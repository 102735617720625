/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 
* @author      : Ananthakumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Common Dashboard
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1511          |  25/3/24    |   Nandha  |  Crew and foreman login dont redirect to common dashboard page
*
*
*/

import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import { useEffect, useState } from "react";
import Tenant from "./components/tenant-webapp/tenantList/Tenant";
import { getUserAccount } from "./services/TenantService";

const CommonDashboard = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [hasEmail, setHasEmail] = useState(false);
    const { logout } = useOidc();

    console.log(accessTokenPayload.emails[0]);

    const fetchUserAccount = async () => {
        try {
            const response = await getUserAccount(accessTokenPayload.emails[0], accessToken);
            if (response.data) {
                setHasEmail(true);
            } else {
                logout('/');
            }
        } catch (error) {
            console.error(error);
            logout('/');
        }
    };

    useEffect(() => {
        fetchUserAccount();
    }, []);

    if (hasEmail) {
        return <Tenant />;
    } else {
        return null;
    }
};

export default CommonDashboard;
