import { executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const createTenantProvision = async (payload: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tenantprovision/createTenant`;
  return await executePostData(url, payload, accessToken);
};

export const getAllTenantcount = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tenantprovision/tenant/count`;
  return await executeGetData(url, accessToken);
};

export const getAllTenants = async (accessToken: any, page:any, size:any) => {
  const url = `${ConfigUrl.baseURL}/tenantprovision/getAllTenantDetails?page=${page}&size=${size}`;
  return await executePostData(url, null, accessToken);
};
export const updateTenantProvision = async (id: any, payload: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tenants/updateTenant/id/${id}`;
  return await executePutData(url, payload, accessToken);
};
export const createTenantAdmin = async (payload: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tenantprovision/createTenantAdmin`;
  return await executePostData(url, payload, accessToken);
};
export const getTenantAdmin = async (tenantId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tenantprovision/getTenantAdmin/tenantId/${tenantId}`;
  return await executeGetData(url, accessToken);
};
export const updateTenantAdmin = async (payload: any, roles: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tenantprovision/updateTenantAdmin/roles/${roles}`;
  return await executePutData(url, payload, accessToken);
};
export const findEmailDetails = async (tenantId: any, email: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tenantprovision/findTenantAdmin/tenantId/${tenantId}/email/${email}`;
  return await executeGetData(url, accessToken);
};

export const getUserAccount = async (email: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/userAccounts/email/${email}`;
  return await executeGetData(url, accessToken);
};

export const getVersionDetails = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/version/getVersions`; 
  return await executeGetData(url, accessToken);
};