/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   :30/05/23
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : for adding tenant
* -----------------------------------------------------------------------------0
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1548         |  5/4/24    |   Nandha      |  Otp Generator toast message removed and dialog implemented
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Alert, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Snackbar, Typography, useMediaQuery, useTheme, CircularProgress, Backdrop } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { GoogleMap, useJsApiLoader, MarkerF, StandaloneSearchBox } from '@react-google-maps/api';
import { Stack } from '@mui/system';

import { useOidcAccessToken } from "@axa-fr/react-oidc";

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import "../createbid/selectorComponent/TaskSelector.scss";
import Header from '../Header';
import { tokens } from '../../hooks/theme';
import { useLocation, useNavigate } from 'react-router-dom';

import './TenantProvisioningPage.scss';
import { createTenantProvision, updateTenantProvision } from '../../services/TenantService';
import gmslogo from "../../assets/logo.gif";

const containerStyle = {
    width: '100%',
    height: '300px'
};
const libraries: any = ['places'];

const TenantProvisioning: React.FC = () => {
    let location = useLocation()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries
    })
    // //console.log(location.state.row)
    const [map, setMap] = React.useState(null);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [role, setRole] = React.useState<any>([] as any[]);
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [MobileNumberValid, setMobileNumberValid] = React.useState(true);
    const [message, setMessage] = React.useState<any>({

        country: '',
        zipcode: '',
        state: '',
        address: '',
        city: '',
        phoneNo: '',
        email: '',
        displayName: '',

    });
    const [loading, setLoading] = React.useState(false);

    const [show, setShow] = React.useState(false);
    const { action, customerUid } = location.state || {};
    //console.log("action", action);
    //console.log("selected", customerUid);
    const [tenantData, setTenantData] = React.useState<any>({
        address: "",
        name: "",
        country: "",
        zipcode: '',
        state: "",
        city: "",
        location: "",
        // assetType: "Residencial",
        // stories: null,
        status: "Active",
        customerUid: "",
        phoneNo: '',
        email: '',
        // name:'',
        displayName: '',
        tenantId:''

    });
    // const tenantDataCreation=()=>{

    // }
    const handleCreateTenant = () => {
        let validated = validation();
        if (validated === true) {
            setLoading(true);
            //console.log(tenantData)

            createTenantProvision(tenantData, accessToken).then(res => {
                //console.log(res);
                const updatedTenantData = {
                    ...tenantData,
                    tenantId: res.data, // Assuming res.data contains tenantId
                    name: res.data // Assuming res.data contains name
                };
                setTenantData(updatedTenantData);

                if (res.ok === true) {
                    navigate("/tenantuser", { state: { tenantData: updatedTenantData } });
                }
            });
        } else {
            // Handle validation error, e.g., show error message to user
            //console.log("Validation failed");
        }
    }
    const cancel=()=>{
        navigate('/tenant')
    }
    const componentData = React.useRef<any>()
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);
    let navigate = useNavigate();
    const [selectedFile, setSelectedFile] = React.useState<File | string>();
    const [image, setImage] = React.useState<any>(null);
    const [toastMessage, setToastMessage] = React.useState('');
    let tenantDataObject = location.state?.row;
    //console.log(location.state?.row)
    const inputRef = React.useRef<any>();
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);
    const [successType, setSuccessType] = React.useState('');
    const convertLocation = (locationString: any) => {
        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    }

    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(componentData.current);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    const onClick = (e: google.maps.MapMouseEvent) => {
        componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() }
        let locationObject: any = {}
        const geocoder = new google.maps.Geocoder();
        const fulladdress: any[] = [];
        geocoder
            .geocode({ location: componentData.current })
            .then((response) => {
                if (response.results[0]) {
                    //console.log(response.results[0]);
                    // eslint-disable-next-line array-callback-return
                    response.results[0].address_components.map((address: any) => {
                        if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("plus_code") || address.types.includes("landmark") || address.types.includes("premise")) {
                            fulladdress.push(address.long_name);
                            // setLocation({...location,address:fulladdress.join(",")});
                            // Object.assign(location,{address:fulladdress.join(",")});
                            locationObject['address'] = fulladdress.join(",");
                        }
                        else if (address.types.includes("locality")) {
                            // setLocation({...location,city:address.long_name});
                            locationObject['city'] = address.long_name;
                        }
                        else if (address.types.includes("administrative_area_level_1")) {
                            // setLocation({...location,state:address.long_name});
                            locationObject['state'] = address.long_name
                        }
                        else if (address.types.includes("country")) {
                            // setLocation({...location,country:address.long_name});
                            locationObject['country'] = address.long_name
                        }
                        else if (address.types.includes("postal_code")) {
                            // setLocation({...location,zipcode:address.long_name});
                            locationObject['zipcode'] = address.long_name
                        }
                    })
                }
                setTenantData({ ...tenantData, zipcode: locationObject.zipcode, country: locationObject.country, address: locationObject.address, city: locationObject.city, state: locationObject.state, location: Object.values(componentData.current).join(",") });
                // Object.assign(location,locationObject);
                // setLocation(locationObject);
            })
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
            });
        } else {
            // //console.log("Geolocation is not supported by this browser.");
        }
    }
    // const showPosition = (position: any) => {
    //     //console.log(componentData.current);
    //     componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
    //     //console.log(componentData.current);
    //     //console.log({ lat: position.coords.latitude, lng: position.coords.longitude });
    //     // tenantData['location'] = componentData.current;
    //     // setTenantData({ ...tenantData,  location: componentData.current });
    // }

    React.useEffect(() => {
        //getLocation();
        // getUserSession(accessToken)
        // .then(res => {

        //   setRole(res.role.name);
        //   //  //console.log(res);
        // })

        tenantDataObject = location.state?.row;
        if (tenantDataObject !== undefined) {
            setTenantData(tenantDataObject);
            if (tenantDataObject.location != null) {
                componentData.current = convertLocation(tenantDataObject?.location);

            }
        }

    }, []);
    //console.log(tenantDataObject)

    const validateMobileNumber = (mobileNo) => {
        return /^\d{10}$/.test(mobileNo);

    };
    const inputchange = (e: any) => {
        // if(e.target.name === "stories"){
        //     setTenantData({
        //         ...tenantData,
        //         [e.target.name]: parseInt(e.target.value)

        //     });
        // }
        // else if(e.target.name === "area"){
        //     setTenantData({
        //         ...tenantData,
        //         [e.target.name]: parseFloat(e.target.value)

        //     });
        // }else{
        const { name, value } = e.target;

        setTenantData({
            ...tenantData,
            [name]: value

        });
        if (name === 'phoneNo' ) {
            const isValidMobileNumber = validateMobileNumber(value);
            setMobileNumberValid(isValidMobileNumber);
        }
        // }
    };
    const handleClick = (message: any) => {
        setOpen(true);
        setToastMessage(message);
    };


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const validation = () => {
        let validated: any = true;

        if (tenantData.state === '') {
            // setMessage({...message,state:'Enter Your State'});
            message.state = 'Enter Your State';
            validated = false;

        }
        if (tenantData.city === '') {
            // setMessage({...message,city:'Enter Your City'});
            message.city = 'Enter Your City';
            validated = false;

        }
        if (tenantData.country === '') {
            //setMessage({...message,country:'Enter Your Country'});
            message.country = 'Enter Your Country';
            validated = false;

        }

        if (tenantData.zipcode === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.zipcode = 'Enter Your zipcode';
            validated = false;

        }
        if (tenantData.address === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.address = 'Enter Your address';
            validated = false;

        }
       
        if (!MobileNumberValid || tenantData.phoneNo === null || tenantData.phoneNo === "" || tenantData.phoneNo === undefined) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.phoneNo = 'Enter Your phone number';
            validated = false;

        }
        if (tenantData.displayName === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.displayName = 'Enter Your display name';
            validated = false;

        }
        // if (tenantData.name === '') {
        //     // setMessage({...message,stories:'Enter Your Stories'});
        //     message.name = 'Enter Your address';
        //     validated = false;

        // }
        if ((tenantData.email === '') || (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tenantData.email))) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.email = 'Enter Your email';
            validated = false;

        }
        return validated;

    }
    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);
        setError(false);
        setNoChange(false)
        // onNextPage();
    }
    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }
    const handleNextChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)
        navigate("/tenantuser", { state: { tenantData } });

    }


    const handleSubmit = async (e: any) => {
        //setTenantData({ ...tenantData, customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        Object.assign(tenantData, { customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        //console.log(tenantData);
        setCount(count + 1);
        let formData = new FormData();
        formData.append("file", image);
        let validated = validation();
        // if (validated === true) {
        //     let result = await saveAsset(tenantData, accessToken);
        //     let uploadResult = await saveAssetDocument(formData, result.data, accessToken);
        //     if (result.status === 201) {
        //         // handleClick('Inserted Successfully');
        //         setSuccessType('save');
        //         setSuccess(true);
        //         // setTimeout(() => {
        //         //     onNextPage();
        //         // }, 2000);
        //     }
        //     else
        //     {
        //         // handleClick('Not Inserted');

        //         setError(true);
        //     }
        // }
        // else{
        //     handleClick('Not Inserted');
        // }
    };
    const updateHandleSubmit = async () => {
        setCount(count + 1);
        let validated = validation();
        let formData = new FormData();
        formData.append("file", image);
        //console.log(tenantDataObject)

        if (tenantDataObject.address === tenantData?.address && tenantDataObject.displayName === tenantData?.displayName && tenantDataObject.name === tenantData?.name && tenantDataObject.country === tenantData?.country
            && tenantDataObject.zipcode === tenantData?.zipcode && tenantDataObject.state === tenantData?.state && tenantDataObject.city === tenantData?.city
            && tenantDataObject.phoneNo === tenantData?.phoneNo) {
            // handleClick('No valued Changed');
            setNoChange(true);

        }
        else {
            //console.log(tenantData, tenantDataObject.id)
            if (validated === true) {
                if (tenantData?.location != null) {
                    if (typeof (tenantData?.location) != "string") {
                        let data = tenantData;
                        data.location = Object.values(tenantData?.location).join(",")
                        setTenantData(data);
                    }
                }
                let result = await updateTenantProvision(tenantDataObject.id, tenantData, accessToken);
                //console.log(result)

                // let uploadResult = await editAssetDocument(formData, assetId, accessToken);
                if (result.status === 200 || result.status === 201) {
                    //console.log(result)
                    // handleClick('Updated Successfully');
                    setSuccessType('update');
                    setSuccess(true);

                    navigate("/tenantuser", { state: { tenantData } });

                    // setTimeout(() => {
                    //     onNextPage();
                    // }, 2000);
                }
                else {
                    // handleClick('Not Updated');

                    setError(true);
                }
            }

        }
    };
    // const onNextPage = () => {
    //     if (action === "addProperty") {
    //         navigate("/createbid");
    //     } else {
    //         navigate("/assetList");
    //     }
    // };

    // const Goback=()=>{
    //     navigate("/assetList");
    // }
    const getFile = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(URL.createObjectURL(file));
        setImage(file);
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setTenantData({
                        ...tenantData,
                        tenantLogo: reader.result,

                    })
                }
            };
            reader.onerror = (error) => {
                console.error('Error occurred while reading the file:', error);
            };
        }
        // Do something with the selected file, e.g., upload or process it
        //console.log('Selected file:', selectedFile);
    };
    const handleCardClick = () => {
        const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
        fileInput.click();
    };

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        const fulladdress: any[] = [];
        let locationObject: any = {}
        if (place) {
            place.address_components.map((address: any) => {
                if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
                    fulladdress.push(address.long_name);
                    // setLocation({...location,address:fulladdress.join(",")});
                    // Object.assign(location,{address:fulladdress.join(",")});
                    locationObject['address'] = fulladdress.join(",");
                }
                else if (address.types.includes("locality")) {
                    // setLocation({...location,city:address.long_name});
                    locationObject['city'] = address.long_name;
                }
                else if (address.types.includes("administrative_area_level_1")) {
                    // setLocation({...location,state:address.long_name});
                    locationObject['state'] = address.long_name;
                }
                else if (address.types.includes("country")) {
                    // setLocation({...location,country:address.lon;g_name});
                    locationObject['country'] = address.long_name;
                }
                else if (address.types.includes("postal_code")) {
                    // setLocation({...location,zipcode:address.long_name});
                    locationObject['zipcode'] = address.long_name;
                }
                else {
                    locationObject['zipcode'] = '';
                    locationObject['address'] = fulladdress.join(",");

                }
            })

            componentData.current = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            setTenantData({ ...tenantData, zipcode: locationObject.zipcode, country: locationObject.country, address: locationObject.address, city: locationObject.city, state: locationObject.state, location: Object.values(componentData.current).join(",") });
        }
    }
    const handleTenantUserPage = () => {
        // handleCreateTenant();
        setCount(count + 1);
        let formData = new FormData();
        formData.append("file", image);
        let validated = validation();
        if (validated === true) {
            handleCreateTenant();

            //      navigate("/tenantuser"
            //      ,
            //   { state: { tenantData } }
            // );
        }
        else {
            console.log("not validated")
        }
    }
    const inputchangeForTenantName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let newValue = value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().substring(0, 8); // Remove special characters and spaces, convert to lowercase, and limit to 8 characters

        // Additional validation for 8 characters consisting of lowercase letters and digits
        if (/^[a-z0-9]{8}$/.test(newValue)) {
            setTenantData({ ...tenantData, [name]: newValue });
        } else {
            // Show error message or handle invalid input
        }
    };

    const isSuccessMessage = toastMessage === 'Inserted Successfully' || toastMessage === 'Updated Successfully';


    return (
        <>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12} sm={12}>
                    <Box
                        display={smScreen ? "flex" : "block"}
                        flexDirection={smScreen ? "row" : "column"}
                        alignItems={smScreen ? "center" : "start"}
                        m="1px 0"
                    >
                        <Grid item sm={6}>
                            {tenantDataObject ? <Header title="Edit  Tenant provisioning" subtitle={""} /> : <Header title="Create Tenant provisioning" subtitle={""} />}
                        </Grid>
                        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
                            {/* {tenantDataObject ? (
                    <Button variant="contained"  className='sucessButton' size="medium" onClick={() => updateHandleSubmit(tenantDataObject?.id)}>
                        Update
                    </Button>
                ) : (
                    <Button className='sucessButton' variant="contained" size="medium" onClick={handleSubmit}>
                        Save
                    </Button>
                )} */}
                            {/* <Button
                    variant="contained"
                    className='primaryTonalButton'
                    size="medium"
                    onClick={Goback}
                    startIcon={<ArrowBackIcon className='personIcon' />}
                >
                    Go Back to My Assets
                </Button> */}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>


            {/* </Stack> */}


            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
                <Alert onClose={handleClose} severity={isSuccessMessage ? 'success' : 'warning'} sx={{ width: '100%' }} >
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Grid container justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12}>
                    {/* {loading ?<CircularProgress size={24} />: */}

                    <Box>

                        <Card className='POCard' variant="outlined" sx={{ p: 3, maxWidth: "1500px" }} >
                            <Grid container spacing={2} columns={13} className='tenantCard'>
                                <Grid item xs={13} sm={13} md={3} lg={3} xl={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className={loading ? 'blur-background' : ''}>
                                            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                                                {/* <CircularProgress color="inherit" />Loading... */}
                                                <Grid container alignItems="center" justifyContent="center">
                                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                                        <img src={gmslogo} alt="Image" className="topBarLogo" />
                                                    </Grid>
                                                    <Grid item md={12} style={{ textAlign: 'center' }}>

                                                        <Typography className="loading" variant='h4' color="blue">Please Wait . . .</Typography>

                                                    </Grid>
                                                </Grid>
                                            </Backdrop>

                                            <Card variant="outlined" className='tenantImageUpload'
                                                onClick={handleCardClick}
                                            >
                                                <label>
                                                    {typeof selectedFile === "string" ? (
                                                        <img src={selectedFile} alt="Selected" className='provisioningImage'/>
                                                    ) : (
                                                        <Stack sx={{ textAlign: 'center', cursor: "pointer", }}>
                                                            <Typography>
                                                                <AddIcon />
                                                            </Typography>
                                                            <Typography>
                                                                Click to Upload company logo
                                                            </Typography>
                                                        </Stack>
                                                    )}
                                                </label>
                                                <input
                                                    type="file"
                                                    id="image-upload-input"
                                                    onChange={(e) => getFile(e)}
                                                    style={{ display: "none" }}
                                                    accept="image/*"
                                                />

                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}> <Typography className='assetSubHeader' fontSize={20} sx={{ color: colors.greenAccent[1000] }}>Enter Tenant Information</Typography></Grid>
                                        {/* <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.name === '' && count !== 0) || !/^[a-z0-9]{8,}$/.test(tenantData.name)}
                                                id="demo-helper-text-aligned"
                                                label="Tenant Name"
                                                name="name"
                                                size="small"
                                                fullWidth
                                                required
                                                value={tenantData.name}
                                                helperText={(tenantData.name === '' && count !== 0) ? message.name : !/^[a-z0-9]{8,}$/.test(tenantData.name) ? 'Please enter at least 8 characters consisting of lowercase letters and digits' : null}
                                                onChange={(e) => inputchange(e)}
                                                // InputProps={{ readOnly: !!tenantData.name }}

                                            /></Grid> */}
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.displayName === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Company Name"
                                                name="displayName"
                                                size="small"
                                                fullWidth
                                                required
                                                value={tenantData.displayName}
                                                // value={tenantDataObject ? tenantDataObject?.propertyName : tenantData.propertyName}
                                                // required
                                                helperText={(tenantData.displayName === '' && count !== 0) ? message.displayName : null}
                                                onChange={(e) => inputchange(e)}
                                            /></Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                // error={(tenantData.phoneNo === '' && count !== 0) }
                                                error={(!MobileNumberValid && count !== 0) || (tenantData.phoneNo === '' && count !== 0)}
                                                helperText={!MobileNumberValid ? "Invalid mobile number" : null}
                                                id="demo-helper-text-aligned"
                                                label="Mobile no"
                                                name="phoneNo"
                                                size="small"
                                                fullWidth
                                                required
                                                value={tenantData.phoneNo}

                                                onChange={(e) => inputchange(e)}
                                                // type="number"
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                            /></Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.alternatephoneNo === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Secondary Mobile no"
                                                name="alternatephoneNo"
                                                size="small"
                                                fullWidth
                                                // required
                                                value={tenantData.alternatephoneNo}
                                                // value={tenantDataObject ? tenantDataObject?.propertyName : tenantData.propertyName}
                                                // required
                                                helperText={(tenantData.alternatephoneNo === '' && count !== 0) ? message.alternatephoneNo : null}
                                                onChange={(e) => inputchange(e)}
                                            /></Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.email === '' && count !== 0) || (count !== 0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tenantData.email))}
                                                id="demo-helper-text-aligned"
                                                label="Email"
                                                name="email"
                                                size="small"
                                                fullWidth
                                                required
                                                value={tenantData.email}
                                                helperText={
                                                    tenantData.email === '' && count !== 0
                                                        ? message.email
                                                        : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tenantData.email)
                                                            ? 'Please enter a valid email address'
                                                            : null
                                                }
                                                onChange={(e) => inputchange(e)}
                                            // InputProps={{ readOnly: !!tenantData.email }}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={6}>
                                        <TextField
                                                error={(tenantData.displayName === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Display Name"
                                                name="displayName"
                                                size="small"
                                                fullWidth
                                                required
                                                value={tenantData.displayName}
                                                // value={tenantDataObject ? tenantDataObject?.propertyName : tenantData.propertyName}
                                                // required
                                                helperText={(tenantData.displayName === '' && count !== 0) ? message.displayName : null}
                                                onChange={(e) => inputchange(e)}
                                            /></Grid> */}
                                        <Grid item xs={6}>
                                            {isLoaded
                                                &&
                                                <StandaloneSearchBox
                                                    onLoad={ref => inputRef.current = ref}
                                                    onPlacesChanged={handlePlaceChanged}
                                                >
                                                    <TextField
                                                        error={(tenantData.address === '' && count !== 0)}
                                                        id="Street address"
                                                        label="Street address"
                                                        name="address"
                                                        size="small"
                                                        fullWidth
                                                        value={tenantData.address}
                                                        // value={tenantDataObject ? tenantDataObject?.address : tenantData.address}
                                                        required
                                                        helperText={(tenantData.address === '' && count !== 0) ? message.address : null}
                                                        // helperText={message.address}
                                                        onChange={(e) => inputchange(e)}
                                                    />
                                                </StandaloneSearchBox>}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.city === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="City"
                                                size="small"
                                                fullWidth
                                                required
                                                name="city"
                                                value={tenantData.city}
                                                // value={tenantDataObject ? tenantDataObject?.city : tenantData.city}
                                                helperText={(tenantData.city === '' && count !== 0) ? message.city : null}
                                                onChange={(e) => inputchange(e)}

                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.state === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="State"
                                                size="small"
                                                fullWidth
                                                name="state"
                                                required
                                                value={tenantData.state}
                                                // value={tenantDataObject ? tenantDataObject?.state : tenantData.state}
                                                onChange={(e) => inputchange(e)}
                                                helperText={(tenantData.state === '' && count !== 0) ? message.state : null}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.country === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Country"
                                                size="small"
                                                fullWidth
                                                value={tenantData.country}
                                                // value={tenantDataObject ? tenantDataObject?.country : tenantData.country}
                                                helperText={(tenantData.country === '' && count !== 0) ? message.country : null}
                                                name="country"
                                                required
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.zipcode === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Zip Code"
                                                size="small"
                                                name="zipcode"
                                                fullWidth
                                                value={tenantData.zipcode}
                                                // value={tenantDataObject ? tenantDataObject?.zipcode : tenantData.zipcode}
                                                required
                                                helperText={(tenantData.zipcode === '' && count !== 0) ? message.zipcode : null}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.description === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Description"
                                                size="small"
                                                name="description"
                                                fullWidth
                                                value={tenantData.description}
                                                // value={tenantDataObject ? tenantDataObject?.zipcode : tenantData.zipcode}
                                                required
                                                helperText={(tenantData.description === '' && count !== 0) ? message.description : null}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.stories === null && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Number of stories"
                                                size="small"
                                                fullWidth
                                                name="stories"
                                                type='number'
                                                // required
                                                helperText={(tenantData.stories === '' && count !== 0) ? message.stories : null}
                                                // value={tenantDataObject ? tenantDataObject?.stories : tenantData.stories}
                                                value={tenantData.stories}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(tenantData.area === null && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Area in sq"
                                                size="small"
                                                fullWidth
                                                name="area"
                                                type='number'
                                                // required
                                                helperText={(tenantData.area === '' && count !== 0) ? message.area : null}
                                                // value={tenantDataObject ? tenantDataObject?.stories : tenantData.stories}
                                                value={tenantData.area}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid> */}
                                        {/* <Grid item xs={6}>

                                            <Typography>Is this asset your Billing Address?</Typography>
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox size='small' />}
                                                label="Yes"
                                                labelPlacement="end"
                                            />

                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ marginTop: 1 }}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'}>
                                        <Typography><MapOutlinedIcon fontSize='small' /></Typography>
                                        <Typography>Choose from MAP </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={13}>
                                    {isLoaded ? (
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={componentData.current}
                                            zoom={10}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}
                                            onClick={onClick}
                                        >
                                            <MarkerF
                                                position={componentData.current}
                                            >
                                            </MarkerF>

                                            <></>

                                        </GoogleMap>
                                    ) : <></>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} justifyContent="flex-end" mt={2}>

                                {/* {props.rows !== null && props.rows !== undefined? ( */}
                                {/* <Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={updateHandleSubmit}>
                                        Update
                                    </Button>
                                </Grid>
                            ) : ( */}
                                {tenantDataObject !== null && tenantDataObject !== undefined ? (<Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={updateHandleSubmit}>
                                        Next
                                    </Button>
                                </Grid>
                                ) : (

                                    <Grid item mt={1}>
                                        <Button className='successButton' variant="contained" size="medium" onClick={handleTenantUserPage}>
                                            Next
                                        </Button>
                                    </Grid>
                                )}
                                {/* )} */}
                                <Grid item>
                                    <Button className='dangerButton' variant="contained" size="medium" onClick={cancel}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>

                        </Card>
                    </Box>
                    {/* } */}
                    {/* <Box m={2} className="poFooter">
                        {tenantDataObject ? <Button className="button" variant="contained" size="medium" onClick={() => updateHandleSubmit(tenantDataObject?.id)}>Update</Button>
                            : <Button className="button" variant="contained" size="medium" onClick={handleSubmit}>Save</Button>}

                            
                    </Box> */}
                    <Dialog open={success} >
                        <DialogTitle className='success'>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                    Success
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>   {successType === 'save' ? 'Asset added Successfully' : 'Asset Updated Successfully'}</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>
                            </DialogActions>
                        </Box>
                    </Dialog>

                    <Dialog open={error} >
                        <DialogTitle className='error'>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                    Error
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>{successType === 'save' ? 'Error Couldnot  Inserted' : 'Error Couldnot  Updated'}</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
                            </DialogActions>
                        </Box>

                    </Dialog>


                    <Dialog open={noChange} >
                        <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Are you sure you dont want to change any data in tenant provisioning</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Cancel</Button>
                                <Button variant='contained' className="NoChangeButton" onClick={handleNextChange}>ok</Button>

                            </DialogActions>
                        </Box>
                    </Dialog>


                </Grid>
            </Grid>

        </>
    );
}

export default TenantProvisioning;