import { useEffect, useState } from "react";
// import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { Box, useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { getCollapseMode } from "../hooks/theme";
import Home from "../pages/Home";
import PageRoutes from "../pages/Routes";

// import FloatChatbot from "../pages/Chatbot/Floatchatbot";
const MainLayout: React.FC = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [sidebarCollapseState, setCollapse] = useState(
    getCollapseMode(smScreen)
  );

  return (
    <>      
        <Topbar />
        <main
          id="main"
          className={
            useMediaQuery(theme.breakpoints.down("md"))
              ? undefined
              : sidebarCollapseState.contentMargin
          }
        >
          <Box sx={{ flexGrow: 1, margin: "15px" }} className="">
            <Toolbar />
            <PageRoutes />
          </Box>
        </main>      
    </>
  );
};

export default MainLayout;


