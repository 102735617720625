/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 30/5/2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : for adding tenant admin
* -----------------------------------------------------------------------------0
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Alert, Backdrop, Button, Card, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { GoogleMap, useJsApiLoader, MarkerF, StandaloneSearchBox } from '@react-google-maps/api';
import { Stack } from '@mui/system';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import "../createbid/selectorComponent/TaskSelector.scss";
import Header from '../Header';
import { tokens } from '../../hooks/theme';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { createTenantAdmin, findEmailDetails, getTenantAdmin, updateTenantAdmin } from '../../services/TenantService';
import gmslogo from "../../assets/logo.gif";
// import './TenantProvisioningPage.scss';

const containerStyle = {
    width: '100%',
    height: '300px'
};
const libraries: any = ['places'];

const TenantUserSetup: React.FC = () => {
    let location = useLocation()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries
    })
    let row = location.state.tenantData;
    //console.log(location.state.tenantData)
    const [map, setMap] = React.useState(null);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [role, setRole] = React.useState<any>([] as any[]);
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    const [message, setMessage] = React.useState<any>({
        name: '',
        country: '',
        zipCode: '',
        state: '',
        address: '',
        city: '',
        birthdate: null,
        firstName: '',
        lastName: '',
        mobileNo: '',
        email: '',
        gender: '',



    });
    const [openDialogEmail, setOpenDialogEmail] = React.useState<boolean>(false);
    const [show, setShow] = React.useState(false);
    // const { action, customerUid } = location.state || {}; 
    // //console.log("action", action);
    // //console.log("selected", customerUid);
    const [assetData, setAssetData] = React.useState<any>({
        address: "",
        name: "",
        country: "",
        zipCode: '',
        state: "",
        city: "",
        location: "",
        status: "Active",
        birthdate: null,
        firstName: '',
        middlename: '',
        lastName: '',
        mobileNo: '',
        email: '',
        gender: '',
        tenantId: row.name,
        username: '',
        avatar: '',
        roles: 'admin',
        active: true


    });
    console.log(assetData);

    const componentData = React.useRef<any>()
    const theme = useTheme();
    const navigate = useNavigate();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);

    const [selectedFile, setSelectedFile] = React.useState<File | string>();
    const [image, setImage] = React.useState<any>(null);
    const [toastMessage, setToastMessage] = React.useState('');
    const [assetDataObject, setAssetDataObject] = React.useState<any>(null);
    const inputRef = React.useRef<any>();
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [hasData, setHasData] = React.useState(false);
    const hasEmailRef = React.useRef<boolean>(false);
    const [MobileNumberValid, setMobileNumberValid] = React.useState(true);

    const [noChange, setNoChange] = React.useState(false);
    const [successType, setSuccessType] = React.useState('');
    const convertLocation = (locationString: any) => {
        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    }
    const findUserAlreadyExist = async (tenantId: any, email: any) => {
        const res = await findEmailDetails(tenantId, email, accessToken);
        //console.log(res.data);
        hasEmailRef.current = res.data;
    };
    const handleEmailClose = () => {
        setOpenDialogEmail(false);

    };
    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(componentData.current);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    const onClick = (e: google.maps.MapMouseEvent) => {
        componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() }
        let locationObject: any = {}
        const geocoder = new google.maps.Geocoder();
        const fulladdress: any[] = [];
        geocoder
            .geocode({ location: componentData.current })
            .then((response) => {
                if (response.results[0]) {
                    //console.log(response.results[0]);
                    // eslint-disable-next-line array-callback-return
                    response.results[0].address_components.map((address: any) => {
                        if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("plus_code") || address.types.includes("landmark") || address.types.includes("premise")) {
                            fulladdress.push(address.long_name);
                            // setLocation({...location,address:fulladdress.join(",")});
                            // Object.assign(location,{address:fulladdress.join(",")});
                            locationObject['address'] = fulladdress.join(",");
                        }
                        else if (address.types.includes("locality")) {
                            // setLocation({...location,city:address.long_name});
                            locationObject['city'] = address.long_name;
                        }
                        else if (address.types.includes("administrative_area_level_1")) {
                            // setLocation({...location,state:address.long_name});
                            locationObject['state'] = address.long_name
                        }
                        else if (address.types.includes("country")) {
                            // setLocation({...location,country:address.long_name});
                            locationObject['country'] = address.long_name
                        }
                        else if (address.types.includes("postal_code")) {
                            // setLocation({...location,zipCode:address.long_name});
                            locationObject['zipCode'] = address.long_name
                        }
                    })
                }
                setAssetData({ ...assetData, zipCode: locationObject.zipCode, country: locationObject.country, address: locationObject.address, city: locationObject.city, state: locationObject.state, location: Object.values(componentData.current).join(",") });
                // Object.assign(location,locationObject);
                // setLocation(locationObject);
            })
    }
    const getTenantAdmins = () => {
        getTenantAdmin(row?.name, accessToken)
            .then((res) => {
                //console.log(res);
                if (res?.data !== null) {
                    let d = res?.data;
                    d['tenantId'] = row?.name;
                    d['roles'] = 'admin';
                    // setAssetData(d);
                    setAssetDataObject(d);
                    setHasData(true);

                    // setAssetData(d);
                    // Object.assign(assetData,{tenantId: row.name})
                    setSelectedFile(res.data.avatar)


                }
                setAssetData(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
            });
        } else {
            // //console.log("Geolocation is not supported by this browser.");
        }
    }

    // const showPosition = (position: any) => {
    //     //console.log(componentData.current);
    //     componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
    //     //console.log(componentData.current);
    //     //console.log({ lat: position.coords.latitude, lng: position.coords.longitude });
    //     // assetData['location'] = componentData.current;
    //     // setAssetData({ ...assetData,  location: componentData.current });
    // }

    React.useEffect(() => {
        getTenantAdmins();

        if (row.name !== null) {
            setAssetData((prevData) => ({
                ...prevData,
                tenantId: row.name,
                roles: "admin"
            }));
        } else if (row.tenantId !== null) {
            setAssetData((prevData) => ({
                ...prevData,
                tenantId: row.tenantId,
                roles: "admin"
            }));
        } else {
            setAssetData((prevData) => ({
                ...prevData,
                tenantId: row.name
            }));
        }
    }, []);

    const validateMobileNumber = (mobileNo) => {
        return /^\d{10}$/.test(mobileNo);

    };

    const inputchange = (e: any) => {
        if (e.target.name === "email") {
            setAssetData({
                ...assetData,
                username: e.target.value,
                email: e.target.value

            });
        }

        if (e.target.name === "mobileNo") {
            console.log("mobileno")
            const isValidMobileNumber = validateMobileNumber(e.target.value);
            setMobileNumberValid(isValidMobileNumber);
            setAssetData({
                ...assetData,
                mobileNo: e.target.value,


            });
        }


        else {
            setAssetData({
                ...assetData,
                [e.target.name]: e.target.value, roles: 'admin',

            });
        }
    };
    const handleClick = (message: any) => {
        setOpen(true);
        setToastMessage(message);
    };


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const createAdmin = () => {

    }
    const validation = () => {
        let validated: any = true;
        if (assetData.displayName === '') {
            // setMessage({...message,propertyName:'Enter Property Name'});
            message.displayName = 'Enter Property Name';
            validated = false;

        }
        // if (assetData.state === '') {
        //     // setMessage({...message,state:'Enter Your State'});
        //     message.state = 'Enter Your State';
        //     validated = false;

        // }
        // if (assetData.city === '') {
        //     // setMessage({...message,city:'Enter Your City'});
        //     message.city = 'Enter Your City';
        //     validated = false;

        // }
        // if (assetData.country === '') {
        //     //setMessage({...message,country:'Enter Your Country'});
        //     message.country = 'Enter Your Country';
        //     validated = false;

        // }
        if (assetData.firstName === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.firstName = 'Enter Your First Name';
            validated = false;

        }
        if (assetData.lastName === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.lastName = 'Enter Your Last Name';
            validated = false;

        }
        if ((assetData.email === '') || (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(assetData.email))) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.email = 'Enter Your Email';
            validated = false;

        }
        if ((assetData.mobileNo === '') || !/^\d{10}$/.test(assetData.mobileNo)) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.mobileNo = 'Enter Your phone no';
            validated = false;

        }
        if (assetData.gender === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.gender = 'Select Your Gender';
            validated = false;

        }
        if (assetData.birthdate === null) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.birhdate = 'Enter Your Birth Date';
            validated = false;

        }
        // if (assetData.zipCode === '') {
        //     // setMessage({...message,stories:'Enter Your Stories'});
        //     message.zipCode = 'Enter Your ZipCode';
        //     validated = false;

        // }
        // if (assetData.address === '') {
        //     // setMessage({...message,stories:'Enter Your Stories'});
        //     message.address = 'Enter Your address';
        //     validated = false;

        // }
        return validated;

    }
    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);
        setError(false);
        setNoChange(false);
        navigate('/tenant')
        // onNextPage();
    }
    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }
    const handleGoBAck = () => {
        navigate('/tenantprovision', { state: { row } });
    }


    const handleSubmit = async (e: any) => {
        let d = location.state.tenantData;
        d['tenantId'] = row?.name;
        d['roles'] = 'admin';
        // setAssetData(d);
        //console.log(row.name,assetData.email)
        await findUserAlreadyExist(row.name, assetData.email);

        //setAssetData({ ...assetData, customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        // Object.assign(assetData,{customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        //console.log(assetData);
        if (hasEmailRef.current === true) {
            setOpenDialogEmail(true);
        } else {
            setCount(count + 1);
            let formData = new FormData();
            formData.append("file", image);
            let validated = validation();
            if (validated === true) {
                setLoading(true);
                console.log("validated");
                //console.log(assetData)
                let result = await createTenantAdmin(assetData, accessToken);
                setLoading(false);

                // let uploadResult = await createAdmin(formData, result.data, accessToken);
                // //console.log(result)
                if (result.data === true) {
                    // handleClick('Inserted Successfully');
                    setSuccessType('save');
                    setSuccess(true);
                    // setTimeout(() => {
                    //     onNextPage();
                    // }, 2000);
                }
                else {
                    // handleClick('Not Inserted');

                    setError(true);
                }
            }
            else {
                // handleClick('Not Inserted');
                console.log(" not validated")

            }
        }
    };
    const handleDateChange = (event: any) => {
        //console.log(event.$d);
        const formattedDate = dayjs(event.$d).format('YYYY-MM-DD');
        setAssetData({
            ...assetData,
            birthdate: formattedDate
        });
    }
    const updateHandleSubmit = async () => {
        //console.log(assetData,assetDataObject)


        setCount(count + 1);
        let validated = validation();
        let formData = new FormData();
        formData.append("file", image);
        if (assetDataObject.firstName === assetData.firstName && assetDataObject.lastName === assetData.lastName && assetDataObject.middlename === assetData.midlename
            && assetDataObject.gender === assetData.gender && assetDataObject.email === assetData.email && assetDataObject.alternateMobileNo === assetData.alternateMobileNo && assetDataObject.mobileNo === assetData.mobileNo
            && assetDataObject.birthdate === assetData.birthdate && assetDataObject.avatar === assetData.avatar) {
            // handleClick('No valued Changed');
            setNoChange(true);

        }
        else {
            if (validated === true) {
                // if (typeof (assetData.location) != "string") {
                //     let data = assetData;
                //     data.location = Object.values(assetData.location).join(",")
                //     setAssetData(data);
                // }
                let result = await updateTenantAdmin(assetData, "admin", accessToken);
                //console.log(result,assetData,assetDataObject)
                // let uploadResult = await updateTenantAdmin(formData, assetId, accessToken);
                if (result.status === 200 || result.status === 201) {
                    // handleClick('Updated Successfully');
                    setSuccessType('update');
                    setSuccess(true);
                    // setTimeout(() => {
                    //     onNextPage();
                    // }, 2000);
                }
                else {
                    // handleClick('Not Updated');

                    setError(true);
                }
            }

        }
    };
    // const onNextPage = () => {
    //     if (action === "addProperty") {
    //         navigate("/createbid");
    //     } else {
    //         navigate("/assetList");
    //     }
    // };

    // const Goback=()=>{
    //     navigate("/assetList");
    // }
    const getFile = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(URL.createObjectURL(file));
        setImage(file);
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setAssetData({
                        ...assetData,
                        avatar: reader.result,

                    })
                }
            };
            reader.onerror = (error) => {
                console.error('Error occurred while reading the file:', error);
            };
        }
        // Do something with the selected file, e.g., upload or process it
        //console.log('Selected file:', selectedFile);
    };
    // setAssetData({
    //     ...assetData,
    //     avatar: file
    // });

    // };
    const handleCardClick = () => {
        const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
        fileInput.click();
    };

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        const fulladdress: any[] = [];
        let locationObject: any = {}
        if (place) {
            place.address_components.map((address: any) => {
                if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
                    fulladdress.push(address.long_name);
                    // setLocation({...location,address:fulladdress.join(",")});
                    // Object.assign(location,{address:fulladdress.join(",")});
                    locationObject['address'] = fulladdress.join(",");
                }
                else if (address.types.includes("locality")) {
                    // setLocation({...location,city:address.long_name});
                    locationObject['city'] = address.long_name;
                }
                else if (address.types.includes("administrative_area_level_1")) {
                    // setLocation({...location,state:address.long_name});
                    locationObject['state'] = address.long_name;
                }
                else if (address.types.includes("country")) {
                    // setLocation({...location,country:address.lon;g_name});
                    locationObject['country'] = address.long_name;
                }
                else if (address.types.includes("postal_code")) {
                    // setLocation({...location,zipCode:address.long_name});
                    locationObject['zipCode'] = address.long_name;
                }
                else {
                    locationObject['zipCode'] = '';
                    locationObject['address'] = fulladdress.join(",");

                }
            })

            componentData.current = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            setAssetData({ ...assetData, zipCode: locationObject.zipCode, country: locationObject.country, address: locationObject.address, city: locationObject.city, state: locationObject.state, location: Object.values(componentData.current).join(",") });
        }
    }

    const isSuccessMessage = toastMessage === 'Inserted Successfully' || toastMessage === 'Updated Successfully';


    return (
        <>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12} sm={12}>
                    <Box
                        display={smScreen ? "flex" : "block"}
                        flexDirection={smScreen ? "row" : "column"}
                        alignItems={smScreen ? "center" : "start"}
                        m="1px 0"
                    >
                        <Grid item sm={6}>
                            {assetDataObject ? <Header title="Edit Tenant User" subtitle={""} /> : <Header title="Create Tenant User" subtitle={""} />}
                        </Grid>
                        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
                            {/* {assetDataObject ? (
                    <Button variant="contained"  className='sucessButton' size="medium" onClick={() => updateHandleSubmit(assetDataObject.id)}>
                        Update
                    </Button>
                ) : (
                    <Button className='sucessButton' variant="contained" size="medium" onClick={handleSubmit}>
                        Save
                    </Button>
                )} */}
                            {/* <Button
                    variant="contained"
                    className='primaryTonalButton'
                    size="medium"
                    onClick={Goback}
                    startIcon={<ArrowBackIcon className='personIcon' />}
                >
                    Go Back to My Assets
                </Button> */}

                        </Grid>
                    </Box>
                </Grid>
            </Grid>


            {/* </Stack> */}


            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
                <Alert onClose={handleClose} severity={isSuccessMessage ? 'success' : 'warning'} sx={{ width: '100%' }} >
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Grid container justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12}>
                    <Box>
                        <Card className='POCard' variant="outlined" sx={{ p: 3, maxWidth: "1500px" }} >
                            <Grid container spacing={2} columns={13} className='tenantCard'>
                                <Grid item xs={13} sm={13} md={3} lg={3} xl={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className={loading ? 'blur-background' : ''}>
                                            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                                                {/* <CircularProgress color="inherit" /> */}
                                                <Grid container alignItems="center" justifyContent="center">
                                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                                        <img src={gmslogo} alt="Image" className="topBarLogo" />
                                                    </Grid>
                                                    <Grid item md={12} style={{ textAlign: 'center' }}>

                                                        <Typography className="loading" variant='h4' color="blue">Please Wait . . .</Typography>

                                                    </Grid>
                                                </Grid>
                                            </Backdrop>

                                            <Card variant="outlined" className='tenantImageUpload'
                                                onClick={handleCardClick}
                                            >
                                                <label>
                                                    {typeof selectedFile === "string" ? (
                                                        <img className='tenantImageUpload' src={selectedFile} alt="Selected" />
                                                    ) : (
                                                        <Stack sx={{ textAlign: 'center', cursor: "pointer", }}>
                                                            <Typography>
                                                                <AddIcon />
                                                            </Typography>
                                                            <Typography>
                                                                Click to Upload profile
                                                            </Typography>
                                                        </Stack>
                                                    )}
                                                </label>
                                                <input
                                                    type="file"
                                                    id="image-upload-input"
                                                    onChange={(e) => getFile(e)}
                                                    style={{ display: "none" }}
                                                    accept="image/*"
                                                />

                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}> <Typography className='assetSubHeader' fontSize={20} sx={{ color: colors.greenAccent[1000] }}>Enter Tenant Administrator's Information</Typography></Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                error={(assetData.firstName === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="First Name"
                                                name="firstName"
                                                size="small"
                                                fullWidth
                                                required
                                                value={assetData.firstName}
                                                // value={assetDataObject ? assetDataObject.propertyName : assetData.propertyName}
                                                // required
                                                helperText={(assetData.firstName === '' && count !== 0) ? message.firstName : null}
                                                onChange={(e) => inputchange(e)}
                                            /></Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                error={(assetData.middleName === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Middle Name"
                                                name="middleName"
                                                size="small"
                                                fullWidth
                                                required
                                                value={assetData.middleName}
                                                // value={assetDataObject ? assetDataObject.propertyName : assetData.propertyName}
                                                // required
                                                helperText={(assetData.middleName === '' && count !== 0) ? message.middleName : null}
                                                onChange={(e) => inputchange(e)}
                                            /></Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                error={(assetData.lastName === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Last Name"
                                                name="lastName"
                                                size="small"
                                                fullWidth
                                                required
                                                value={assetData.lastName}
                                                // value={assetDataObject ? assetDataObject.propertyName : assetData.propertyName}
                                                // required
                                                helperText={(assetData.lastName === '' && count !== 0) ? message.lastName : null}
                                                onChange={(e) => inputchange(e)}
                                            /></Grid>
                                        {/* <Grid item xs={6}> */}
                                        <Grid item xs={12} md={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        label="Birth Date"
                                                        name='birthdate'
                                                        onChange={(e: any) => handleDateChange(e)}
                                                        value={dayjs(assetData ? assetData.birthdate : null)}
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                size: 'small',
                                                                error: (assetData.birthdate?.$d?.toString() === 'Invalid Date' && count !== 0),
                                                                helperText: (assetData.birthdate?.$d?.toString() === 'Invalid Date' && count !== 0) ? 'Enter your date of birth' : null
                                                            }
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} md={6}>

                                            <FormControl fullWidth required error={(assetData.gender === '' && count !== 0)}>
                                                <InputLabel id="demo-simple-select-label" >
                                                    Gender
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={assetData?.gender || ''}
                                                    label="Gender"
                                                    name="gender"
                                                    onChange={(e: any) => inputchange(e)}
                                                    size='small'
                                                >
                                                    <MenuItem value={"male"}>Male </MenuItem>
                                                    <MenuItem value={"female"}>Female</MenuItem>
                                                </Select>
                                                {/* {genderError && <FormHelperText>{(assetData.gender === '' && count !== 0) ? message.gender : null}</FormHelperText>} */}
                                            </FormControl>


                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                // error={(assetData.mobileNo === '' && count !== 0)}
                                                error={(!MobileNumberValid && count !== 0) || (assetData.mobileNo === '' && count !== 0)}
                                                helperText={!MobileNumberValid ? "Invalid mobile number" : null}
                                                id="demo-helper-text-aligned"
                                                label="Mobile no"
                                                name="mobileNo"
                                                size="small"
                                                fullWidth
                                                required
                                                value={assetData.mobileNo}
                                                onChange={(e) => inputchange(e)}
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                            /></Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.alternateMobileNo === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Secondary Mobile no"
                                                name="alternateMobileNo"
                                                size="small"
                                                fullWidth
                                                // required
                                                value={assetData.alternateMobileNo}
                                                // value={assetDataObject ? assetDataObject.propertyName : assetData.propertyName}
                                                // required
                                                helperText={(assetData.alternateMobileNo === '' && count !== 0) ? message.alternateMobileNo : null}
                                                onChange={(e) => inputchange(e)}
                                            /></Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                // error={(assetData.email === '' && count !== 0)}
                                                error={(assetData.email === '' && count !== 0) || (count !== 0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(assetData.email))}
                                                id="demo-helper-text-aligned"
                                                label="email"
                                                name="email"
                                                size="small"
                                                fullWidth
                                                required
                                                value={assetData.email}
                                                // value={assetDataObject ? assetDataObject.propertyName : assetData.propertyName}
                                                // required
                                                // helperText={(assetData.email === '' && count !== 0) ? message.email : null}
                                                helperText={
                                                    assetData.email === '' && count !== 0
                                                        ? message.email
                                                        : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(assetData.email)
                                                            ? 'Please enter a valid email address'
                                                            : null}
                                                onChange={(e) => inputchange(e)}
                                            // disabled={assetData.email !== ''}
                                            // inputProps={{ ...(row !== null ? { readOnly: true } : {}) }}

                                            /></Grid>

                                        {/* <Grid item xs={6}>
                                        <TextField
                                                error={(assetData.displayName === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Display Name"
                                                name="displayName"
                                                size="small"
                                                fullWidth
                                                required
                                                value={assetData.displayName}
                                                // value={assetDataObject ? assetDataObject.propertyName : assetData.propertyName}
                                                // required
                                                helperText={(assetData.displayName === '' && count !== 0) ? message.displayName : null}
                                                onChange={(e) => inputchange(e)}
                                            /></Grid> */}
                                        {/*       <Grid item xs={6}>
                                            {isLoaded
                                                &&
                                                <StandaloneSearchBox
                                                    onLoad={ref => inputRef.current = ref}
                                                    onPlacesChanged={handlePlaceChanged}
                                                >
                                                    <TextField
                                                        error={(assetData.address === '' && count !== 0)}
                                                        id="Street address"
                                                        label="Street address"
                                                        name="address"
                                                        size="small"
                                                        fullWidth
                                                        value={assetData.address}
                                                        // value={assetDataObject ? assetDataObject.address : assetData.address}
                                                        required
                                                        helperText={(assetData.address === '' && count !== 0) ? message.address : null}
                                                        // helperText={message.address}
                                                        onChange={(e) => inputchange(e)}
                                                    />
                                                </StandaloneSearchBox>}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.city === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="City"
                                                size="small"
                                                fullWidth
                                                required
                                                name="city"
                                                value={assetData.city}
                                                // value={assetDataObject ? assetDataObject.city : assetData.city}
                                                helperText={(assetData.city === '' && count !== 0) ? message.city : null}
                                                onChange={(e) => inputchange(e)}

                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.state === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="State"
                                                size="small"
                                                fullWidth
                                                name="state"
                                                required
                                                value={assetData.state}
                                                // value={assetDataObject ? assetDataObject.state : assetData.state}
                                                onChange={(e) => inputchange(e)}
                                                helperText={(assetData.state === '' && count !== 0) ? message.state : null}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.country === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Country"
                                                size="small"
                                                fullWidth
                                                value={assetData.country}
                                                // value={assetDataObject ? assetDataObject.country : assetData.country}
                                                helperText={(assetData.country === '' && count !== 0) ? message.country : null}
                                                name="country"
                                                required
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.zipCode === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Zip Code"
                                                size="small"
                                                name="zipCode"
                                                fullWidth
                                                value={assetData.zipCode}
                                                // value={assetDataObject ? assetDataObject.zipCode : assetData.zipCode}
                                                required
                                                helperText={(assetData.zipCode === '' && count !== 0) ? message.zipCode : null}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.stories === null && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Number of stories"
                                                size="small"
                                                fullWidth
                                                name="stories"
                                                type='number'
                                                // required
                                                helperText={(assetData.stories === '' && count !== 0) ? message.stories : null}
                                                // value={assetDataObject ? assetDataObject.stories : assetData.stories}
                                                value={assetData.stories}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.area === null && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Area in sq"
                                                size="small"
                                                fullWidth
                                                name="area"
                                                type='number'
                                                // required
                                                helperText={(assetData.area === '' && count !== 0) ? message.area : null}
                                                // value={assetDataObject ? assetDataObject.stories : assetData.stories}
                                                value={assetData.area}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid> */}
                                        {/* <Grid item xs={6}>

                                            <Typography>Is this asset your Billing Address?</Typography>
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox size='small' />}
                                                label="Yes"
                                                labelPlacement="end"
                                            />

                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*      <Grid container spacing={1} sx={{ marginTop: 1 }}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'}>
                                        <Typography><MapOutlinedIcon fontSize='small' /></Typography>
                                        <Typography>Choose from MAP</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={13}>
                                    {isLoaded ? (

                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={componentData.current}
                                            zoom={10}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}
                                            onClick={onClick}
                                        >
                                            <MarkerF
                                                position={componentData.current}
                                            >
                                            </MarkerF>

                                            <></>

                                        </GoogleMap>
                                    ) : <></>}
                                </Grid>
                            </Grid>
*/}
                            <Grid container spacing={2} justifyContent="flex-end" mt={2}>

                                {/* {props.rows !== null && props.rows !== undefined? ( */}
                                {/* <Grid item mt={1}>
    <Button className='successButton' variant="contained" size="medium" onClick={updateHandleSubmit}>
        Update
    </Button>
</Grid>
) : ( */}
                                <Grid item mt={1}>
                                    {hasData ? (
                                        <Button className='successButton' variant="contained" size="medium" onClick={updateHandleSubmit}>
                                            Update
                                        </Button>
                                    ) : (
                                        <Button className='successButton' variant="contained" size="medium" onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    )}
                                </Grid>

                                {/* )} */}
                                <Grid item>
                                    <Button className='dangerButton' variant="contained" size="medium" onClick={handleGoBAck} >
                                        Go Back
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                    {/* <Box m={2} className="poFooter">
                        {assetDataObject ? <Button className="button" variant="contained" size="medium" onClick={() => updateHandleSubmit(assetDataObject.id)}>Update</Button>
                            : <Button className="button" variant="contained" size="medium" onClick={handleSubmit}>Save</Button>}


                    </Box> */}
                    <Dialog open={success} >
                        <DialogTitle className='success'>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                    Success
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>   {successType === 'save' ? ' Added Successfully' : ' Updated Successfully'}</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>
                            </DialogActions>
                        </Box>
                    </Dialog>

                    <Dialog open={error} >
                        <DialogTitle className='error'>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                    Error
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>{successType === 'save' ? 'Error Couldnot  Inserted' : 'Error Occured'}</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
                            </DialogActions>
                        </Box>

                    </Dialog>
                    <Dialog open={openDialogEmail} >
                        <DialogTitle className='warning'>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <PriorityHighIcon sx={{ fontSize: "50px", color: "white" }} />
                                </Typography>
                                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                    Warning
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent>
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                                    Email already registered
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' className='warning' onClick={handleEmailClose}>Close</Button>
                            </DialogActions>
                        </Box>
                    </Dialog>

                    <Dialog open={noChange} >
                        <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                            </DialogActions>
                        </Box>
                    </Dialog>


                </Grid>
            </Grid>

        </>
    );
}

export default TenantUserSetup;