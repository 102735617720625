import React from 'react';

import Header from '../../components/Header';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import Typography from '@mui/material/Typography';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import { TimelineOppositeContent } from '@mui/lab';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';





const Timelines: React.FC = () => {
    return (
        <div>
            <Header title="Timeline components Templates" subtitle={null} />
            <Card>
                <CardContent>
                    <h4>Normal Timeline Without outlined</h4>
                    <Grid container spacing={1} >
                        <Grid xs={4} md={3} >
                            <h5>Position Left</h5>
                            <Timeline position="left">
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 1</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 2</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 3</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 4</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />

                                    </TimelineSeparator>
                                    <TimelineContent>Step 5</TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Grid>
                        <Grid xs={4} md={3} >
                            <h5>Position Right</h5>
                            <Timeline >

                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 1</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 2</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 3</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 4</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />

                                    </TimelineSeparator>
                                    <TimelineContent>Step 5</TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Grid>
                        <Grid xs={4} md={6} >
                            <h5>Position alternate</h5>
                            <Timeline position='alternate' >

                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 1</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 2</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 3</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Step 4</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />

                                    </TimelineSeparator>
                                    <TimelineContent>Step 5</TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <br />

            <Card>
                <CardContent>
                    <h4>Normal Timeline With outlined</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={6} >
                            <Timeline>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Eat</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Code</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Sleep</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" />
                                    </TimelineSeparator>
                                    <TimelineContent>Repeat</TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>

            <br />

            <Card>
                <CardContent>
                    <h4> Timeline Dot colors</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={6} >
                            <Timeline>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="secondary" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Secondary</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="success" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Success</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary" />
                                    </TimelineSeparator>
                                    <TimelineContent>Primary</TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>

            <br />

            <Card>
                <CardContent>
                    <h4>Horizontal Timeline </h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={6} >
                            <Stepper >
                                <Step>
                                    <StepLabel > Setting</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel >Add People</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel> watched</StepLabel>
                                </Step>

                            </Stepper>
                        </Grid>

                        <Grid xs={4} md={6} >
                            <Stepper >
                                <Step>

                                    <TimelineDot />
                                </Step>
                                <Step>

                                    <TimelineDot />
                                </Step>
                                <Step>
                                    <TimelineDot />
                                </Step>

                            </Stepper>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>

            <br />

            <Card>
                <CardContent>
                    <h4> Timeline with icons</h4>
                    <Grid container spacing={8} >
                        <Grid xs={4} md={6} >
                            <Timeline>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary"  >
                                            < SettingsIcon />
                                        </TimelineDot>
                                        <TimelineConnector />


                                    </TimelineSeparator>
                                    <TimelineContent>Settings</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            < GroupAddIcon />
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Add Participants</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            <LaptopMacIcon />
                                        </TimelineDot>
                                    </TimelineSeparator>
                                    <TimelineContent>Views</TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>

            <br />

            <Card>
                <CardContent>
                    <h4>Timeline With Opposite content </h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={7} >
                            <Timeline position="left">
                                <TimelineItem>
                                    <TimelineOppositeContent variant="body2" sx={{ py: '30px' }}>
                                        <h2 >Our company starts its operations</h2>
                                        <Typography variant="h4" gutterBottom>
                                            11 March 1991
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                                            blanditiis tenetur
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            < RocketLaunchIcon/>
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '30px' }}>
                                        <Typography variant="h6" color="primary">
                                            Year 1991
                                        </Typography>

                                    </TimelineContent>

                                </TimelineItem>

                                <TimelineItem>
                                <TimelineOppositeContent variant="body2" sx={{ py: '30px' }}>
                                        <h2 >
                                        First customer
                                        </h2>
                                        <Typography variant="h4" gutterBottom>
                                        19 March 2000
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                        Quisque ornare dui nibh, sagittis egestas nisi luctus nec. Sed
        aliquet laoreet sapien, eget pulvinar lectus maximus vel.
        Phasellus suscipit porta mattis.
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            < GroupAddIcon  />
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '30px' }}>
                                        <Typography variant="h6" color="primary">
                                            Year 1995
                                        </Typography>

                                    </TimelineContent>
                                </TimelineItem>

                                <TimelineItem>
                                <TimelineOppositeContent variant="body2" sx={{ py: '30px' }}>
                                        <h2 > Earned the first million $!</h2>
                                        <Typography variant="h4" gutterBottom>
                                            15 October 1995
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Nulla ac tellus convallis, pulvinar nulla ac, fermentum diam. Sed
                                            et urna sit amet massa dapibus tristique non finibus ligula. Nam
                                            pharetra libero nibh, id feugiat tortor rhoncus vitae. Ut suscipit
                                            vulputate mattis.
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            < CurrencyBitcoinIcon />
                                        </TimelineDot>
                                        <TimelineConnector />

                                    </TimelineSeparator>


                                    <TimelineContent sx={{ py: '30px' }}>
                                        <Typography variant="h6" color="primary">
                                            Year 2000
                                        </Typography>

                                    </TimelineContent>

                                </TimelineItem>


                            </Timeline>

                        </Grid>


                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

export default Timelines;





