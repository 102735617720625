// WelcomePage.tsx
import React from 'react';
import { Container, Typography, Button, CssBaseline, Card, CardMedia, Grid, Box, Stack } from '@mui/material';
import { useOidc } from '@axa-fr/react-oidc';
import gmslogogif from "../assets/Login Page - GMS-01-01.svg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';

const Home: React.FC = () => {
  const { login, logout, isAuthenticated } = useOidc();
  const pageStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh', // Make sure the container stretches to the full height of the viewport
    backgroundColor: '#5c78f7', // Your desired background color
  };

  const containerStyles: React.CSSProperties = {
    marginTop: '8rem',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a shadow
    backgroundColor: 'white', // Container background color
  };


  return (

    <Box sx={{ position: "relative", overflow: "hidden", height: "100vh" }}>
      <img src={gmslogogif} alt="Your Alt Text Here" style={{ maxWidth: '100%', height: 'auto', overflow: "hidden" }} />
      {!isAuthenticated && (
        <Button style={{ position: "absolute", bottom: "45%", left: "73%", zIndex: "1", minWidth: "160px" }} variant='contained' size='large'
          onClick={() => login('/commondashboard')} endIcon={<ArrowForwardIcon />} >
          Login
        </Button>
      )}
      <Stack direction="row" spacing={1} style={{ position: "absolute", bottom: "3%", left: "70%", transform: "translateX(50%)", zIndex: "1", fontWeight: "500" }}>
        <CopyrightOutlinedIcon />
        <Typography >Copyright (C) GMS Inc - All Rights Reserved</Typography>
      </Stack>
    </Box>



  );
};

export default Home;