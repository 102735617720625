import { CssBaseline, useMediaQuery } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import "./App.scss"
import { createTheme, ThemeProvider } from "@mui/material";
import {
  ColorModeContext,
  tokens,
  themeSettings,
  getCollapseMode,
} from "./hooks/theme";
import { useState } from "react";
import ShowNavBar from "./layouts/ShowNavbar";
import { OidcProvider } from "@axa-fr/react-oidc";
import oidcConfiguration from "./auth/OidcConfig";
import Loading from "./auth/Loading.component";
import { CallBackSuccess } from "./auth/Callback.component";
import Authenticating from "./auth/Authenticating.component";

import "./App.scss"
import Sample from "./pages/sample";
import Home from "./pages/Home";



// import { getUserSessionDetails, UserContext } from "./hooks/UserSession";


const App: React.FC = () => {
  const [theme, setCopyy] = useState(createTheme(themeSettings("light")));
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [sidebarCollapseState, setCollapse] = useState(
    getCollapseMode(smScreen)
  );

  const colors = tokens(theme.palette.mode);

  return (
    <OidcProvider configuration={oidcConfiguration}
      authenticatingComponent={Authenticating}
      loadingComponent={Loading}
      callbackSuccessComponent={CallBackSuccess}>
      <ColorModeContext.Provider value={{ theme, setCopyy }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <ShowNavBar>
            <div className="app" >
              {/* <SidebarCollapseModeContext.Provider
                  value={{ sidebarCollapseState, setCollapse }}
                > */}
              <MainLayout />

            </div>
          </ShowNavBar>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </OidcProvider >
  );
};

export default App;
